import React from "react";
import { Row, Label, FormGroup, Button,Table, ModalHeader, ModalBody,Modal } from "reactstrap";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Notification from './../../../components/Notification';
import {moneyHolding} from '../api/apiStatistic';
import {convertDate,isEmpty,range,getMoneyFormat} from "../../../utils";
import MoneyHoldingStatisticsOnDay from "./moneyHoldingStatisticsOnDay";
class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fromdate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
            todate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
            showModal: false
        };
        this.rowPage = [50,100,200,250];
        this.tableheader1=[
            {label:"Ngày"},
            {label:"Tiền giữ hộ đầu"},
            {label:"Mua vé"},
            {label:"Rút tiền tại quầy"},
            {label:"Rút tiền qua ngân hàng"},
            {label:"Tổng tiền rút"},
            {label:"Nạp tiền tại quầy"},
            {label:"Nạp tiền qua ngân hàng"},
            {label:"Nạp qua Vnpay"},
            // {label:"Nạp qua MOMO"},
            {label:"Tổng tiền nạp"},
            {label:"Khuyến mại"},
            {label:"Trúng thưởng"},
            {label:"Tiền giữ hộ cuối"}
        ];
        this.filter={
            size: 100,
            page:1
        };
    }

    toggle(component){
        this.setState({
            [component]: !this.state[component]
        });
    }

    inputOnchange(event) {
        this.filter[event.target.name] = event.target.value;
    }

    onclickRow(row){
        let state = Object.assign({}, this.state);
        state.showModal=true;
        state.dateTrans = row.createdDate.substr(0, 10);
        state.rowSelect = row;
        this.setState(state);
    }

    getMoneyHoldingStatistic(page){
        if(!!page){
            switch (page) {
                case "+":
                    this.filter.page++;
                    break;
                case "-":
                    this.filter.page--;
                    break;
                default:
                    this.filter.page = page;
                    break;
            }
            if(this.filter.page<1) this.filter.page = 1;
            Object.assign(this.filter,{page: this.filter.page});
        }
        if(this.state.fromdate===null) Object.assign(this.filter,{fromdate: "01-01-2019"},{todate:convertDate(this.state.todate)})
        else Object.assign(this.filter,{fromdate: convertDate(this.state.fromdate)},{todate:convertDate(this.state.todate)})
        moneyHolding(
            this.filter,
            (err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.setState({statisticData: result, pageNum : Math.ceil(result.count/this.filter.size)})
            }
        });
    }

    componentDidMount(){
        this.getMoneyHoldingStatistic(1)
    }

    render() {
        return(
            <React.Fragment>
                        <Modal isOpen={this.state.showModal} className="modal-fullsize">
                            <ModalHeader toggle={this.toggle.bind(this,"showModal")}></ModalHeader>
                            <ModalBody>
                                <MoneyHoldingStatisticsOnDay dateTrans={this.state.dateTrans ? this.state.dateTrans.split("-") : null} total={this.state.rowSelect}/>
                            </ModalBody>
                        </Modal>
                <Row className='justify-content-center m-0'>
                    <FormGroup className='mb-3'>
                        <Label className="d-block">Ngày bắt đầu</Label>
                        <DatePicker
                            isClearable
                            showPopperArrow={false}
                            selected={this.state.fromdate}
                            onChange={date =>this.setState({fromdate: date})}
                            dateFormat="dd-MM-yyyy"
                        />
                    </FormGroup>
                    <FormGroup className='mb-3 ml-3'>
                        <Label className="d-block">Ngày kết thúc</Label>
                        <DatePicker
                            isClearable
                            showPopperArrow={false}
                            selected={this.state.todate===null ? new Date(new Date().getTime() - 24 * 60 * 60 * 1000) : this.state.todate}
                            onChange={date => this.setState({todate: date})}
                            dateFormat="dd-MM-yyyy"
                        />
                    </FormGroup>
                    <div className='mt-4 p-2 d-inline'>
                        <Button color='primary' onClick={this.getMoneyHoldingStatistic.bind(this,1)}>
                            Tìm kiếm
                        </Button>
                        <ReactHTMLTableToExcel
                            id="table-xls-button"
                            className="download-table-xls-button float-right d-inline mx-2"
                            table="thong-ke-khach-hang"
                            filename={`THỐNG KÊ TIỀN GỬI THEO KỲ TỪ ${this.filter.fromdate} ĐẾN ${this.filter.todate}`}
                            sheet={`sheet1`}
                            buttonText="Tải xuống"
                        />
                    </div>
                </Row>
                <div className=' mx-4 p-3 mt-2 w-120  text-center'>
                    <Row className='pt-2 p-1 scroll-table  '>
                        <Table className="table-bordered table-header-center" id="thong-ke-khach-hang">
                            <thead>
                                <tr>
                                    {
                                        this.tableheader1.map((header)=>{
                                            return (
                                                <th rowspan={header.row || "1"} colspan={header.col||"1"}>{header.label}</th>
                                            )
                                        })
                                    }
                                </tr>
                                {
                                    isEmpty(this.state.statisticData) ? null :
                                    (
                                        <tr key={0}>
                                            <th>Tổng</th>
                                            <th>{getMoneyFormat(this.state.statisticData.total["totalTotalHoldingStart"]) }</th>
                                            <th>{getMoneyFormat(this.state.statisticData.total["SUB_PAYMENT"]) }</th>
                                            <th>{getMoneyFormat(this.state.statisticData.total["SUB_WITHDRAWAL_AT_STORE"]) }</th>
                                            <th>{getMoneyFormat(this.state.statisticData.total["SUB_WITHDRAWAL_BANKING"]) }</th>
                                            <th>{getMoneyFormat(parseInt(this.state.statisticData.total["SUB_WITHDRAWAL_AT_STORE"]) + parseInt(this.state.statisticData.total["SUB_WITHDRAWAL_BANKING"]))}</th>
                                            <th>{getMoneyFormat(this.state.statisticData.total["ADD_DEPOSIT_AT_STORE"]) }</th>
                                            <th>{getMoneyFormat(this.state.statisticData.total["ADD_BANKING_TRANSFER"]) }</th>
                                            <th>{getMoneyFormat(this.state.statisticData.total["ADD_VNPAY"]) }</th>
                                            {/* <th>{getMoneyFormat(this.state.statisticData.total["ADD_MOMO"]) }</th> */}
                                            <th>
                                                {getMoneyFormat(   parseInt(this.state.statisticData.total["ADD_DEPOSIT_AT_STORE"]) + 
                                                    parseInt(this.state.statisticData.total["ADD_BANKING_TRANSFER"]) + 
                                                    parseInt(this.state.statisticData.total["ADD_VNPAY"])  + 
                                                    parseInt(this.state.statisticData.total["ADD_MOMO"])
                                                )}
                                            </th>
                                            <th>{getMoneyFormat(0)}</th>
                                            <th>{getMoneyFormat(this.state.statisticData.total["ADD_WON_LOTTERY"]) }</th>
                                            <th>{getMoneyFormat(this.state.statisticData.total["totalTotalHoldingEnd"]) }</th>
                                        </tr>
                                    )
                                }
                            </thead>
                            <tbody>
                                {
                                    isEmpty(this.state.statisticData) ? null :
                                    (
                                        this.state.statisticData.rows.map((row,key,)=>{
                                            return(
                                                <tr key={key} className="cursor-pointer"  onClick={this.onclickRow.bind(this,row)}>
                                                    <td>
                                                        {row.createdDate.substr(0, 10)}
                                                    </td>
                                                    <td>{getMoneyFormat(row.source["holdingMoneyStart"]) }</td>
                                                    <td>{getMoneyFormat(row.source["SUB_PAYMENT"]) }</td>
                                                    <td>{getMoneyFormat(row.source["SUB_WITHDRAWAL_AT_STORE"]) }</td>
                                                    <td>{getMoneyFormat(row.source["SUB_WITHDRAWAL_BANKING"]) }</td>
                                                    <td>{getMoneyFormat(parseInt(row.source["SUB_WITHDRAWAL_AT_STORE"]) + parseInt(row.source["SUB_WITHDRAWAL_BANKING"]))}</td>
                                                    <td>{getMoneyFormat(row.source["ADD_DEPOSIT_AT_STORE"]) }</td>
                                                    <td>{getMoneyFormat(row.source["ADD_BANKING_TRANSFER"]) }</td>
                                                    <td>{getMoneyFormat(row.source["ADD_VNPAY"]) }</td>
                                                    {/* <td>{getMoneyFormat(row.source["ADD_MOMO"]) }</td> */}
                                                    <td>
                                                        {getMoneyFormat(   parseInt(row.source["ADD_DEPOSIT_AT_STORE"]) + 
                                                            parseInt(row.source["ADD_BANKING_TRANSFER"]) + 
                                                            parseInt(row.source["ADD_VNPAY"])  
                                                            +parseInt(row.source["ADD_MOMO"])
                                                        )}
                                                    </td>
                                                    <td>{getMoneyFormat(0)}</td>
                                                    <td>{getMoneyFormat(row.source["ADD_WON_LOTTERY"]) }</td>
                                                    <td>{getMoneyFormat(row.source["holdingMoneyEnd"]) }</td>
                                                </tr>
                                            )
                                        })
                                    )
                                }
                            </tbody>
                        </Table>
                    </Row>
                    <Row className="mt-2">
                        <select class="custom-select" name="size" style={{width:"90px"}} defaultValue={100} onChange={this.inputOnchange.bind(this)}>
                            {
                                this.rowPage.map((option,key)=>{
                                    return( <option key={key} value={option} >{option}</option>)
                                })
                            }
                        </select>
                        <div className="float-right px-2">
                            {
                                isEmpty(this.state.statisticData) ? null
                                :(
                                    <div className="float-right px-2">
                                        <Button disabled={this.filter.page===1} onClick={this.getMoneyHoldingStatistic.bind(this,"-")}>{`<`}</Button>
                                        {
                                            range(1,10).map((index)=>{
                                                return(
                                                    <Button 
                                                        className={`${this.filter.page === index ? "btn-danger" : null}`}
                                                        hidden={index >  this.state.pageNum}
                                                        onClick={this.getMoneyHoldingStatistic.bind(this,index)}
                                                    >
                                                        {index}
                                                    </Button>
                                                )
                                            })
                                        }
                                        <Button hidden={this.filter.page <= 10|| this.filter.page===this.state.pageNum} className="btn-danger">{`${this.filter.page}`}</Button>
                                        <Button hidden={this.state.pageNum<=10} className={`${this.filter.page === this.state.pageNum ? "btn-danger" : null}`} onClick={this.getMoneyHoldingStatistic.bind(this,this.state.pageNum)}>{`...${this.state.pageNum}`}</Button>
                                        <Button disabled={this.filter.page >=  this.state.pageNum} onClick={this.getMoneyHoldingStatistic.bind(this,"+")}>{`>`}</Button>
                                    </div>
                                )
                            }
                        </div>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default Main;
