const config_api = require("../../../config/config").config_api;
const utils = require("../../../utils");
const axios = require('axios');
const moment = require('moment');
function getAllGroup(data,callback){

    axios({
        url: config_api.groupBuyGet+'?'+utils.convertToQuery(data),
        method: 'Get',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        }   

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
function getDetailGroup(data,callback){

    axios({
        url: config_api.groupBuyDetail+'?'+utils.convertToQuery(data),
        method: 'Get',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        }   

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
function updateGroup(data,callback){
    axios({
        url: config_api.groupBuyUpdate,
        method: 'Post',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data:data
    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
function getProduct(id,callback){
    let magikWord='5dccf01a-d1c0-49a4-9eb2-15f9701497c0';
    let magikValue=magikWord+moment().format("HH:mm");
    const crypto = require('crypto');
    var signer = crypto.createHash('sha256').update(magikValue).digest('hex');
    axios({
        url:config_api.product+'/'+id,
        method: 'Get',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken(),
            'checksum':signer
        }    

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
function createGroup(data,callback){
    axios({
        url:config_api.groupBuyCreate,
        method: 'Post',
        headers: {
            "Content-type": "application/json",
            "Authorization": 'Bearer ' + utils.getAuthToken()
        },
        data:data    

    })
    .then(result => {
        return callback(false, result.data)
    })
    .catch(error => {
        if (error.response) {
            return callback(error.response);
        } else if (error.request) {
            return callback("Đường truyền bị gián đoạn");
        } else {
            return callback(error.message);
        }
    });
}
module.exports={
    getAllGroup,
    updateGroup,
    getProduct,
    createGroup,
    getDetailGroup
}