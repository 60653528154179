import React from "react";
import utils from "../../../utils";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import Select from "react-select";
import { Edit, Lock, Unlock } from "react-feather";
import { faStore } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Notification from "../../../components/Notification";
import html2canvas from 'html2canvas'
import {getProduct} from '../api/apiGroupBuy'
const { getNameDistrictFromCode } = require("../../../config/listDistrict");
const { getNameCityFromCode } = require("../../../config/listCity");
const { arrayCity } = require("../../../config/city");
const apiNotifi = require("./../api/apiNotifi");

const apiLocation = require("./../api/apiLocation");
const productDataset = [
  {
      "value": 1,
      "label": "Mega 6/45",
      "slug": "mega",
      "priceArray": [],
      "numberTicket": "6",
      "productOptionArray": [
          {
              "id": 1,
              "name": "Vé đơn",
              "slug": "ve_don",
              "select": 6,
              "startNumber": 1,
              "endNumber": 45,
              "price": 10
          },
          {
              "id": 2,
              "name": "Bao 5",
              "slug": "bao_5",
              "select": 5,
              "startNumber": 1,
              "endNumber": 45,
              "price": 400
          },
          {
              "id": 3,
              "name": "Bao 7",
              "slug": "bao_7",
              "select": 7,
              "startNumber": 1,
              "endNumber": 45,
              "price": 70
          },
          {
              "id": 4,
              "name": "Bao 8",
              "slug": "bao_8",
              "select": 8,
              "startNumber": 1,
              "endNumber": 45,
              "price": 280
          },
          {
              "id": 5,
              "name": "Bao 9",
              "slug": "bao_9",
              "select": 9,
              "startNumber": 1,
              "endNumber": 45,
              "price": 840
          },
          {
              "id": 6,
              "name": "Bao 10",
              "slug": "bao_10",
              "select": 10,
              "startNumber": 1,
              "endNumber": 45,
              "price": 2100
          },
          {
              "id": 7,
              "name": "Bao 11",
              "slug": "bao_11",
              "select": 11,
              "startNumber": 1,
              "endNumber": 45,
              "price": 4620
          },
          {
              "id": 8,
              "name": "Bao 12",
              "slug": "bao_12",
              "select": 12,
              "startNumber": 1,
              "endNumber": 45,
              "price": 9240
          },
          {
              "id": 9,
              "name": "Bao 13",
              "slug": "bao_13",
              "select": 13,
              "startNumber": 1,
              "endNumber": 45,
              "price": 17160
          },
          {
              "id": 10,
              "name": "Bao 14",
              "slug": "bao_14",
              "select": 14,
              "startNumber": 1,
              "endNumber": 45,
              "price": 30030
          },
          {
              "id": 11,
              "name": "Bao 15",
              "slug": "bao_15",
              "select": 15,
              "startNumber": 1,
              "endNumber": 45,
              "price": 50050
          },
          {
              "id": 12,
              "name": "Bao 18",
              "slug": "bao_18",
              "select": 18,
              "startNumber": 1,
              "endNumber": 45,
              "price": 185564
          }
      ]
  },
  {
      "value": 2,
      "label": "Power 6/55",
      "slug": "power",
      "priceArray": [],
      "numberTicket": "6",
      "productOptionArray": [
          {
              "id": 21,
              "name": "Vé đơn",
              "slug": "ve_don",
              "select": 6,
              "startNumber": 1,
              "endNumber": 55,
              "price": 10
          },
          {
              "id": 22,
              "name": "Bao 5",
              "slug": "bao_5",
              "select": 5,
              "startNumber": 1,
              "endNumber": 55,
              "price": 500
          },
          {
              "id": 23,
              "name": "Bao 7",
              "slug": "bao_7",
              "select": 7,
              "startNumber": 1,
              "endNumber": 55,
              "price": 70
          },
          {
              "id": 24,
              "name": "Bao 8",
              "slug": "bao_8",
              "select": 8,
              "startNumber": 1,
              "endNumber": 55,
              "price": 280
          },
          {
              "id": 25,
              "name": "Bao 9",
              "slug": "bao_9",
              "select": 9,
              "startNumber": 1,
              "endNumber": 55,
              "price": 840
          },
          {
              "id": 26,
              "name": "Bao 10",
              "slug": "bao_10",
              "select": 10,
              "startNumber": 1,
              "endNumber": 55,
              "price": 2100
          },
          {
              "id": 27,
              "name": "Bao 11",
              "slug": "bao_11",
              "select": 11,
              "startNumber": 1,
              "endNumber": 55,
              "price": 4620
          },
          {
              "id": 28,
              "name": "Bao 12",
              "slug": "bao_12",
              "select": 12,
              "startNumber": 1,
              "endNumber": 55,
              "price": 9240
          },
          {
              "id": 29,
              "name": "Bao 13",
              "slug": "bao_13",
              "select": 13,
              "startNumber": 1,
              "endNumber": 55,
              "price": 17160
          },
          {
              "id": 30,
              "name": "Bao 14",
              "slug": "bao_14",
              "select": 14,
              "startNumber": 1,
              "endNumber": 55,
              "price": 30030
          },
          {
              "id": 31,
              "name": "Bao 15",
              "slug": "bao_15",
              "select": 15,
              "startNumber": 1,
              "endNumber": 55,
              "price": 50050
          },
          {
              "id": 32,
              "name": "Bao 18",
              "slug": "bao_18",
              "select": 18,
              "startNumber": 1,
              "endNumber": 55,
              "price": 185564
          }
      ]
  }
];
const Product=['','Mega 6/45','Power 6/55']
export class notifiManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          id: 0,
          title: "đang tải",
          content: "đang tải",
          createdDate: "đang tải",
        },
      ],
      showModal: false,

      title: "",
      content: "",
      image:"",
      isForCharity:false,
      productId:null,
      productOptionId:null,
      term:[],
      termSelect:null,
      ticketValue:''
    };
  }
  componentDidMount() {
    apiNotifi.listNotifiCommon(1, (d) => {
      console.log(d);
      this.setState({ ...this.state, data: d });
    });
  }
  toggle = () => {
    this.setState({ ...this.state, showModal: !this.state.showModal });
  };
  onInputChange = (event) => {
    console.log(event);
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };
  submitNotifi = () => {
    if (this.state.title == "" || this.state.content == "") {
      return;
    }
    if(this.state.isForCharity){
      let valueLength=this.state.ticketValue.split(',').length;
      let lengthValidate=productDataset.find(e=>e.value==this.state.productId).productOptionArray.find(e=>e.id==this.state.productOptionId).select
      if(valueLength!=lengthValidate){
        alert("Số lượng bộ số không đúng");return
      }
    }
    this.getScreenShot(()=>{
      apiNotifi.createNotifiCommon({
        title: this.state.title,
        content: this.state.content,
        image:this.state.imgLink,
        imgCapture:this.state.imgCapture,
        isForCharity:this.state.isForCharity,
        productId:this.state.productId,
        productOptionId:this.state.productOptionId,
        term:this.state.termSelect,
        value:this.state.ticketValue

      },()=>{
        let data=this.state.data;
        data.push({id:0,title:this.state.title,content:this.state.content})
        this.setState({...this.state,data:data,showModal:false});
  
      });
    })
   
  };
  onImageFileChange(event) {
    if (event.target.files) {    
        let state = Object.assign({}, this.state);
        let reader = new FileReader();
        reader.onload = e => {
            state.imgSrc = e.target.result;
            this.setState({localFile:state.imgSrc})
        };
       
        apiNotifi.apiAttachImage(event.target.files[0], (err, result) => {
                if (err) {
                    switch (err.data._error_message) {
                        case "Incomplete arguments 'attached_file'":
                            Notification("error","Yêu cầu thêm tệp đính kèm");
                            break;
                        case "Incomplete arguments 'contentType'":
                            Notification("error","Lỗi kiểu dữ liệu");
                            break;
                        case "Extension not allow":
                            Notification("error","Sai định dạng");
                            break;
                        case "Permission denied: ":
                            Notification("error","Không có quyền");
                            break;
                        case "Ticket not found":
                            Notification("error","Vé không còn tồn tại");
                            break;
                        case "Images is null":
                            Notification("error","Ảnh không được để trống");
                            break;
                        case "Too many Term Keno: ":
                            Notification("error","Quá nhiều kỳ");
                            break;
                        case "Nothing change for this Ticket":
                            Notification("error","Yêu cầu cập nhật thông tin vé");
                            break;
                        case "Product not found!":
                            Notification("error","Kiểu vé không hợp lệ");
                            break;
                        case "ProductOption not found!":
                            Notification("error","Kiểu chơi không hợp lệ");
                            break;
                        default:
                            Notification("error", "Có lỗi xảy ra ", "Dung lượng ảnh lớn hơn 5MB hoặc đường truyền lỗi");
                            break;
                    }
                } else {                        
                  console.log(result)              
                    this.setState({...this.state,imgLink:result.pathUrl});
                }
            });
        
        
        reader.readAsDataURL(event.target.files[0]);
    }
}
getScreenShot(callback){
  let c = document.getElementById('image'); // or document.getElementById('canvas');
 
  html2canvas(c).then((canvas)=>{
    var t = canvas.toDataURL().replace("data:image/png;base64,", "");
    console.log(t)
    
    apiNotifi.apiAttachImage(this.base64ToBlob(t,'image/png'), (err, result) => {
      if (err) {
          switch (err.data._error_message) {
              case "Incomplete arguments 'attached_file'":
                  Notification("error","Yêu cầu thêm tệp đính kèm");
                  break;
              case "Incomplete arguments 'contentType'":
                  Notification("error","Lỗi kiểu dữ liệu");
                  break;
              case "Extension not allow":
                  Notification("error","Sai định dạng");
                  break;
              case "Permission denied: ":
                  Notification("error","Không có quyền");
                  break;
              case "Ticket not found":
                  Notification("error","Vé không còn tồn tại");
                  break;
              case "Images is null":
                  Notification("error","Ảnh không được để trống");
                  break;
              case "Too many Term Keno: ":
                  Notification("error","Quá nhiều kỳ");
                  break;
              case "Nothing change for this Ticket":
                  Notification("error","Yêu cầu cập nhật thông tin vé");
                  break;
              case "Product not found!":
                  Notification("error","Kiểu vé không hợp lệ");
                  break;
              case "ProductOption not found!":
                  Notification("error","Kiểu chơi không hợp lệ");
                  break;
              default:
                  Notification("error", "Có lỗi xảy ra ", "Dung lượng ảnh lớn hơn 5MB hoặc đường truyền lỗi");
                  break;
          }
      } else {                              
          this.setState({...this.state,imgCapture:result.pathUrl},()=>{
            callback()
          });
      }
  });
  })
}
 base64ToBlob(base64, mime) 
{
    mime = mime || '';
    var sliceSize = 1024;
    var byteChars = window.atob(base64);
    var byteArrays = [];

    for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
        var slice = byteChars.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: mime});
}
handleSelectProduct(){
  getProduct(this.state.productId,(err,data)=>{
      if(err){}
      else{
          this.setState({term:data.term})
      }
  })
}

  render() {
    const tableColumns = [
      {
        dataField: "id",
        text: "Id",
      },
      {
        dataField: "title",
        text: "Tiêu đề",
      },
      {
        dataField: "content",
        text: "Nội dung",
      },
      {
        dataField: "createdDate",
        text: "Tạo ngày",
      },
      {
        headerFormatter: (column, colIndex) => {
          return (
            <Button
              className="float-center m-2 btn-suscess text-white"
              color="success"
              onClick={() => this.toggle()}
            >
              Tạo thông báo
              <FontAwesomeIcon className="ml-2" icon={faStore} />
            </Button>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <Modal style={{width:'90%',maxWidth:'none'}} isOpen={this.state.showModal}>
          <ModalHeader>Tạo thông báo</ModalHeader>
          <ModalBody>
          <div className="row">
          <div className="col-md-6">
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">Tiêu đề</InputGroupAddon>
              <Input  name="title" onChange={(e) => this.onInputChange(e)} />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">Nội dung</InputGroupAddon>
              <Input name="content" type='textarea' onChange={(e) => this.onInputChange(e)} />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroupAddon addonType="prepend">Ảnh thông báo</InputGroupAddon>
              <input  
                    type='file'
                    accept='image/png,image/jpeg'                  
                   // className='d-none'
                    onChange={this.onImageFileChange.bind(this)}
                    onClick={event => {
                        event.target.value = null;
                    }}
                />
                <image width={100} src={utils.getURLImage(this.state.imgLink)}/>
            </InputGroup>
            <Button style={{margin:5,padding:5}} color={this.state.isForCharity?'secondary':'danger'} onClick={()=>{
                               this.setState({isForCharity:!this.state.isForCharity})
                            }} >
              Tạo thông báo vé từ thiện {this.state.isForCharity?'✅':'❎'}
              </Button>
          {this.state.isForCharity&& <div>
              <FormGroup>
                  <Label>Loại vé</Label>
                  <Select
                      name='productId'
                      className='react-select-container'
                      classNamePrefix='react-select'
                      options={productDataset}
                      onChange={(value)=>{
                          this.setState({productId:value.value},()=>{
                          this.handleSelectProduct();
                          });
                          
                      }}
                  />
              </FormGroup>
              {this.state.productId&&<FormGroup>
                  <Label>Kiểu chơi</Label>
                  <Select
                      name='productOptionId'
                      className='react-select-container'
                      classNamePrefix='react-select'
                      options={productDataset.find(v=>v.value==this.state.productId).productOptionArray.map(e=>{
                          return {
                              value:e.id,
                              label:e.name
                          }
                      })}
                      onChange={(value)=>this.setState({productOptionId:value.value})}
                  />
              </FormGroup>}
              {this.state.term.length>0&&<FormGroup>
                                  <Label>Kỳ mở thưởng</Label>
                                  <Select
                                      name='term'
                                      className='react-select-container'
                                      classNamePrefix='react-select'
                                      options={this.state.term.map(e=>{
                                          return {
                                              value:e.termValue,
                                              label:e.termString+' '+e.date
                                          }
                                      })}
                                      onChange={(value)=>{
                                        
                                          this.setState({termSelect:value.value,timeClose:this.state.term.find(e=>e.termValue==value.value).date.replace("00:00:00","17:30:00")})
                                        
                                      
                                      }}
                                  />
                              </FormGroup>}
                <InputGroup className='mb-3'>
                    <InputGroupAddon addonType='prepend'>Bộ số</InputGroupAddon>
                    <Input name='ticketValue' onChange={this.onInputChange.bind(this)} value={this.state.ticketValue} placeholder="01,02,03..."/>
                </InputGroup>
            </div>}
            </div>
            <div className="col-md-6">
                    <div id={'image'} style={{borderWidth:1,borderRadius:5,padding:10,borderStyle:'solid',width:719,backgroundColor:'#ececec',overflow:'hidden'}}>
                      <p style={{fontWeight:'bold',fontSize:40}}>
                      {this.state.title}
                      </p>
                      <div style={{fontSize:28,whiteSpace:'pre-wrap'}}>
                      {this.state.content}
                      </div>
                      <img
                        src={this.state.localFile}
                        style={{width:'100%'}}
                      />
                    </div>
                    <div>
                      <Button color="secondary" onClick={() => {
this.getScreenShot()
                      }}>
                            Lưu ảnh
                      </Button>
                    </div>
            </div>
</div>
          </ModalBody>

          <ModalFooter>
            <Button color="secondary" onClick={() => this.toggle()}>
              Hủy bỏ
            </Button>
            <Button color="success" onClick={()=>this.submitNotifi()}>Lưu</Button>
          </ModalFooter>
        </Modal>
        <div>
          <div className="overflow-x-auto table-overflow-x">
            <BootstrapTable
              bootstrap4
              bordered={true}
              keyField="id"
              data={this.state.data}
              columns={tableColumns}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default notifiManagement;
