import React from "react";
import { Collapse, Badge } from "reactstrap";
import "react-tabs/style/react-tabs.css";
import { ChevronDown } from "react-feather";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faTasks,
  faChartLine,
  faUsers,
  faCog,
} from "@fortawesome/free-solid-svg-icons";

class SidebarCategory extends React.Component {
  render() {
    const { name, icon: Icon, isOpen, onClick } = this.props;
    return (
      <li className={"sidebar-item "}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          {Icon ? (
            <FontAwesomeIcon
              icon={Icon}
              className="align-middle mr-3"
              size="lg"
            />
          ) : null}
          <span className="align-middle">{name}</span>

          <ChevronDown size={18} className="align-middle float-right" />
        </span>
        <Collapse isOpen={isOpen}>
          {name === "Thống kê" ? (
            <React.Fragment>
              {/* <ul id='item'>
                                <SidebarItem name='Thống kê tiền gửi tài khoản khách hàng' icon={null} setOptionTab={this.props.setOptionTab} id={this.props.id} click='rechargeOnDay' />
                            </ul>*/}
                            <ul id="item">
                <SidebarItem
                  name="Thông tin khách hàng v2"
                  icon={null}
                  setOptionTab={this.props.setOptionTab}
                  id={this.props.id}
                  click="userStatisticalV2"
                />
              </ul>
              <ul id="item">
                <SidebarItem
                  name="Hoạt động nhân viên"
                  icon={null}
                  setOptionTab={this.props.setOptionTab}
                  id={this.props.id}
                  click="staffMonitor"
                />
              </ul>
              <ul id="item">
                <SidebarItem
                  name="Thông tin giao dịch"
                  icon={null}
                  setOptionTab={this.props.setOptionTab}
                  id={this.props.id}
                  click="customerTransactions"
                />
              </ul>
              {/* <ul id="item">
                <SidebarItem
                  name="Thông tin nhân viên"
                  icon={null}
                  setOptionTab={this.props.setOptionTab}
                  id={this.props.id}
                  click="staffStatistical"
                />
              </ul> */}
              <ul id="item">
                <SidebarItem
                  name="Doanh thu"
                  icon={null}
                  setOptionTab={this.props.setOptionTab}
                  id={this.props.id}
                  click="revenueStatistics"
                />
              </ul>
              {/* <ul id="item">
                <SidebarItem
                  name="Tiền gửi theo kỳ"
                  icon={null}
                  setOptionTab={this.props.setOptionTab}
                  id={this.props.id}
                  click="moneyHoldingStatistics"
                />
              </ul> */}
              <ul id="item">
                <SidebarItem
                  name="Tiền gửi theo ngày"
                  icon={null}
                  setOptionTab={this.props.setOptionTab}
                  id={this.props.id}
                  click="moneyHoldingStatisticsOnDay"
                />
              </ul>
              <ul id="item">
                <SidebarItem
                  name="Hủy vé"
                  icon={null}
                  setOptionTab={this.props.setOptionTab}
                  id={this.props.id}
                  click="rejectTicket"
                />
              </ul>
              <ul id="item">
                <SidebarItem
                  name="Thông tin trúng thưởng"
                  icon={null}
                  setOptionTab={this.props.setOptionTab}
                  id={this.props.id}
                  click="winPrize"
                />
              </ul>
              <ul id="item">
                <SidebarItem
                  name="Dời kỳ keno"
                  icon={null}
                  setOptionTab={this.props.setOptionTab}
                  id={this.props.id}
                  click="changeTermKeno"
                />
              </ul>
            </React.Fragment>
          ) : name === "Lịch sử" ? (
            <React.Fragment>
              <ul id="item">
                <SidebarItem
                  name="Lịch sử nhân viên"
                  icon={null}
                  setOptionTab={this.props.setOptionTab}
                  id={this.props.id}
                  click="clerkHistory"
                />
              </ul>
              <ul id="item">
                <SidebarItem
                  name="Lịch sử kế toán"
                  icon={null}
                  setOptionTab={this.props.setOptionTab}
                  id={this.props.id}
                  click="accountantHistory"
                />
              </ul>
              {/* <ul id='item'>
                                <SidebarItem name='Lịch sử nạp, rút tiền' icon={null} setOptionTab={this.props.setOptionTab} id={this.props.id} click='reChargeHistory' />
                            </ul> */}
              <ul id="item">
                <SidebarItem
                  name="Lịch sử admin"
                  icon={null}
                  setOptionTab={this.props.setOptionTab}
                  id={this.props.id}
                  click="adminHistory"
                />
              </ul>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ul id="item">
                <SidebarItem
                  name="Thiết lập chung"
                  icon={null}
                  setOptionTab={this.props.setOptionTab}
                  id={this.props.id}
                  click="configGeneral"
                />
              </ul>
              <ul id="item">
                <SidebarItem
                  name="Thiết lập SEO VESOVIET"
                  icon={null}
                  setOptionTab={this.props.setOptionTab}
                  id={this.props.id}
                  click="configSeo"
                />
              </ul>
              <ul id="item">
                <SidebarItem
                  name="Thiết lập SEO VIETLOTT.MOBI"
                  icon={null}
                  setOptionTab={this.props.setOptionTab}
                  id={this.props.id}
                  click="configSeoMobi"
                />
              </ul>
              <ul id="item">
                <SidebarItem
                  name="Quản lý mã POS"
                  icon={null}
                  setOptionTab={this.props.setOptionTab}
                  id={this.props.id}
                  click="posManagerment"
                />
              </ul>
              <ul id="item">
                <SidebarItem
                  name="Lý do hủy yêu cầu nạp tiền tại cửa hàng"
                  icon={null}
                  setOptionTab={this.props.setOptionTab}
                  id={this.props.id}
                  click="configReasonsNotAllowWithdralsCleark"
                />
              </ul>
              <ul id="item">
                <SidebarItem
                  name="Lý do hủy yêu cầu nạp tiền"
                  icon={null}
                  setOptionTab={this.props.setOptionTab}
                  id={this.props.id}
                  click="configReasonsNotAllowRecharge"
                />
              </ul>
              <ul id="item">
                <SidebarItem
                  name="Lý do hủy yêu cầu rút tiền"
                  icon={null}
                  setOptionTab={this.props.setOptionTab}
                  id={this.props.id}
                  click="configReasonsNotAllowWithdrals"
                />
              </ul>
            </React.Fragment>
          )}
        </Collapse>
      </li>
    );
  }
}

class SidebarItem extends React.Component {
  render() {
    const { name, badgeColor, badgeText, icon: Icon, id, click } = this.props;
    return (
      <li
        onClick={() => {
          this.props.setOptionTab(click);
        }}
        className={id === click ? "sidebar-item active" : "sidebar-item"}
      >
        <NavLink to="#" className="sidebar-link" activeClassName="active">
          {Icon ? (
            <FontAwesomeIcon
              icon={Icon}
              className="align-middle mr-3"
              size="lg"
            />
          ) : null}
          {name}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
}

class SidebarLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      select: null,
      openStatistical: false,
      openHistory: false,
      id: null,
    };
  }

  toggle(component) {
    this.setState({
      [component]: !this.state[component],
    });
  }

  render() {
    return (
      <div
        className="sidebar-content"
        style={{ height: `${window.innerHeight - 120}px` }}
      >
        <PerfectScrollbar>
          <ul className="sidebar-nav">
            <React.Fragment>
              <SidebarItem
                name="Quản lý nhân viên"
                icon={faUsers}
                setOptionTab={this.props.setOptionTab}
                id={this.props.click}
                click="staffmanagement"
              />
              <SidebarItem
                name="Quản lý cửa hàng"
                icon={faList}
                setOptionTab={this.props.setOptionTab}
                id={this.props.click}
                click="storeManagement"
              />
              <SidebarItem
                name="Quản lý thông báo"
                icon={faList}
                setOptionTab={this.props.setOptionTab}
                id={this.props.click}
                click="notifiCommonManagement"
              />
              <SidebarItem
                name="Quản lý Bao chung"
                icon={faList}
                setOptionTab={this.props.setOptionTab}
                id={this.props.click}
                click="groupBuyManager"
              />
              <SidebarCategory
                name="Thống kê"
                icon={faChartLine}
                setOptionTab={this.props.setOptionTab}
                id={this.props.click}
                isOpen={this.state[0]}
                onClick={this.toggle.bind(this, 0)}
              />
              <SidebarCategory
                name="Thiết lập"
                icon={faCog}
                setOptionTab={this.props.setOptionTab}
                id={this.props.click}
                isOpen={this.state[2]}
                onClick={this.toggle.bind(this, 2)}
              />
              <SidebarCategory
                name="Lịch sử"
                icon={faTasks}
                setOptionTab={this.props.setOptionTab}
                id={this.props.click}
                isOpen={this.state[1]}
                onClick={this.toggle.bind(this, 1)}
              />
            </React.Fragment>
          </ul>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SidebarLeft;
