import React from "react";
import { Row,Col, Label, FormGroup, Button,Table,ModalHeader,Modal,ModalBody, Card, CardTitle, CardBody} from "reactstrap";
import DatePicker from "react-datepicker";
import {customerTransactions,getOrder} from '../api/apiStatistic'
import utils from '../../../utils/'
import Notification from './../../../components/Notification';
import LoadingSprinner from '../../../components/LoadingSprinner';
import {rechargeOnDay} from './../../../config/config'
import {exportedExcel} from '../../../components/api/apiExportServer'
import {gender} from '../../../config/config'
import {listPos} from './../api/apiPos'

const {getProductById,getProductOptionById} = require('../../../assets/js/getProduct');

class Filter extends React.Component {
    render() {
        return (
            <span className="m-2 filter-input">
                <Label className="d-block content-title-bold m-0">{this.props.label}</Label>
                {
                    this.props.type==="text" ? (
                        <input name={this.props.name||"nullName"} type="text" classNames="h-100" style={this.props.style} placeholder= {this.props.placeholder||"Tìm kiếm"} onChange={this.props.action}/>
                    ): this.props.type==="option" ?(
                        <select class="custom-select header-select-statistic" name={this.props.name||"nullName"} style={this.props.style} onChange={this.props.action}>
                            <option key="0" value={""}>Tất cả</option>
                            {
                                this.props.optionSelect.map((option,key)=>{
                                    return( <option key={key+1} selected={option.value=='9'} value={option.value || option}>{option.label || option}</option>)
                                })
                            }
                        </select>
                    ): this.props.label
                }
            </span>
        );
    }
}

class ModalOrder extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.showModalOrder && this.props.dataOrder}>
                    <ModalHeader toggle={this.props.closeModal}>
                        <div className="font-weight-bold">
                            {`ORDER ${this.props.dataOrder.id||"null"} - ${this.props.dataOrder.createdDate||"null"}`}
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        {
                            utils.isEmpty(this.props.dataOrder)? null:
                            this.props.dataOrder.ticketArray.map((ticket,key)=>{
                                return(
                                    <Card key = {key}>
                                        <CardTitle>{`Vé: ${getProductById(ticket.productId)}, Kiểu chơi: ${getProductOptionById(ticket.productOptionId)}, ID: #${ticket.id}`}</CardTitle>
                                        <CardBody className="pt-0">
                                            { // hien so
                                                ticket.value.map((rowNumber,index)=>{
                                                    return(
                                                        <div className="d-flex">
                                                            <div className="d-flex align-items-center w-10">
                                                                <span class="text-danger">{String.fromCharCode(65+index)}</span>
                                                            </div>
                                                            <div className="d-flex flex-wrap w-90">
                                                                {
                                                                    rowNumber.number.map((number,k)=>{
                                                                        if(ticket.productOptionId===82){
                                                                            let kenoEvenOddBigSmall={
                                                                                "1": "Lẻ",
                                                                                "2": "Chẵn",
                                                                                "3": "Nhỏ",
                                                                                "4": "Lớn"
                                                                            };
                                                                            return (<span class="mx-1" key={k}>{kenoEvenOddBigSmall[number]}</span>)
                                                                        }
                                                                        return(<span class="mx-1" key={k}>{number}</span>)
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* Ảnh */}
                                            <Row>
                                                <Col md="6" className="text-center">
                                                    <img alt="mat truoc" className="w-90" src={utils.getURLImage(ticket.imageFront)}/>
                                                </Col>
                                                <Col md="6" className="text-center">
                                                    <img alt="mat truoc" className="w-90" src={utils.getURLImage(ticket.imageBack)}/>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    
                                )
                            })
                        }
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fromdate: new Date(),
            todate: new Date(),
            showModalOrder : false,
            dataOrder: []
        };
        this.rowPage = [50,100,200,250];
        this.tableheader1=[
            {label: "STT"},
            {label: "ID Giao dịch"},
            {label: "ID Order"},
            {label: "ID Ticket"},
            {label: "SĐT khách hàng"},
            {label: "Họ tên khách hàng"},
            {label: "User nhân viên"},
            {label: "Hoạt động"},
            {label: "Số tiền"},
            {label: "Số dư trước"},
            {label: "Số dư sau"},
            {label: "Thời gian"},
            {label: "Email"},
            {label: "Giới tính"},
            {label: "CMT/CCCD"},
        ];
        this.filter={
            size: 100,
            page:1,
            fluctuationTypeId:'9'
        };
        this.listPos = [];
        this.loading=false
    }

    inputOnchange(event) {
        this.filter[event.target.name] = event.target.value;
    }

    toggle(component){
        this.setState({
            [component]: !this.state[component]
        });
    }

    getTransactions(page){
        if(this.loading) return;
        this.loading =true;
        if(!!page){
            switch (page) {
                case "+":
                    this.filter.page++;
                    break;
                case "-":
                    this.filter.page--;
                    break;
                default:
                    this.filter.page = page;
                    break;
            }
            if(this.filter.page<1) this.filter.page = 1;
            Object.assign(this.filter,{page: this.filter.page});
        }
        
        if( this.state.fromdate!==null ) 
            Object.assign(this.filter,{fromdate: utils.convertDate(this.state.fromdate)})
        else delete this.filter.fromdate;
        if( this.state.todate!==null ) 
            Object.assign(this.filter,{todate:utils.convertDate(this.state.todate)})
        else delete this.filter.todate;

        customerTransactions(
            this.filter,
            (err, result) => {
                this.loading =false;
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.setState({statisticData: result.rows,total:result.total[0], pageNum : Math.ceil(result.rows.count/this.filter.size)})
            }
        });
    }

    getPoscodeById(id) {
        if(utils.isEmpty(id)) return null;
        let pos = this.listPos.find( p => p.value == id);
        if(utils.isEmpty(pos)) return null
        return pos.label
    }

    handExport(){
        this.setState({loading:true});
        let filter = {...this.filter};
        delete filter.page;
        delete filter.size;
        if( this.state.fromdate!==null ) 
            Object.assign(filter,{fromdate: utils.convertDate(this.state.fromdate)})
        else delete filter.fromdate;
        if( this.state.todate!==null ) 
            Object.assign(filter,{todate:utils.convertDate(this.state.todate)})
        else delete filter.todate
        exportedExcel('exportStatisticTrans',filter,(err,res)=>{
            if(err){
                Notification("error","Vui lòng giới hạn lại thời gian");
            } 
            this.setState({loading:false});
            
        })

    }

    componentDidMount(){
        listPos({},(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.listPos = [];
                result.map(pos=>{
                    this.listPos.push({value: pos.id, label: pos.code});
                })
                this.getTransactions();
            }
        });
    }

    getOrder(orderId, customerId){
        getOrder(
            {orderId:orderId,customerId:customerId},
            (err,result)=>{
                if(err){
                    Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
                } else {
                    this.setState({dataOrder: result, showModalOrder: true})
                }
            }
        )
    }

    onClickRow(orderId, customerId, action){
        if(action !== "Bán vé" ) return(null);
        if(!orderId) return( Notification("error", "Không tìm thấy ID order"));
        if(!customerId) return( Notification("error", "Không tìm thấy ID khách hàng"));
        this.getOrder(orderId, customerId);
    }

    render() {
        return(
            <React.Fragment>
                    {
                        this.state.dataOrder?(
                            <ModalOrder
                                dataOrder = {this.state.dataOrder}
                                showModalOrder = {this.state.showModalOrder}
                                closeModal = {this.toggle.bind(this,"showModalOrder")}
                            />
                        ):null
                    }
                
                <div className='mx-4 p-3 w-120  text-center'>
                    <h2 className="d-inline mb-2">{`Tổng số giao dịch tìm được: ${this.state.statisticData?this.state.statisticData.count:0}`}</h2>
                    <Row className='justify-content-center m-0 mt-3'>
                        <FormGroup className='mb-3'>
                            <Label className="d-block">Ngày bắt đầu</Label>
                            <DatePicker
                                isClearable
                                showPopperArrow={false}
                                selected={this.state.fromdate}
                                onChange={date =>this.setState({fromdate: date})}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>
                        <FormGroup className='mb-3 ml-3'>
                            <Label className="d-block">Ngày kết thúc</Label>
                            <DatePicker
                                isClearable
                                showPopperArrow={false}
                                selected={this.state.todate}
                                onChange={date => this.setState({todate: date})}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>
                        <div className='mt-4 p-2 d-flex'>
                            <Button className=" mb-3" color='primary' onClick={this.getTransactions.bind(this,null)}>
                                Tìm kiếm
                            </Button>
                            {
                                !this.state.loading
                                ? <Button  className="mx-2 mb-3" color='primary' onClick={this.handExport.bind(this)}>
                                        Xuất file
                                    </Button>
                                : <LoadingSprinner/>
                            }
                        </div>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <Filter label="SĐT khách hàng" type="text" name="phoneNumber" action={this.inputOnchange.bind(this)}/>
                        <Filter label="Họ tên khách hàng" type="text" name="fullName" action={this.inputOnchange.bind(this)}/>
                        <Filter label="User nhân viên" type="text" name="username" action={this.inputOnchange.bind(this)}/>
                        <Filter label="Họ tên nhân viên" type="text" name="fullNameStaff" action={this.inputOnchange.bind(this)}/>
                        <Filter label="Mã POS" type="option" name="posId" optionSelect={this.listPos} action={this.inputOnchange.bind(this)}/>
                        <Filter  label="Hoạt động" type="option" name="fluctuationTypeId" optionSelect={utils.changeObjectKey(rechargeOnDay,"id","value")} action={this.inputOnchange.bind(this)}/>
                        <Filter label="Email" type="text" name="email" action={this.inputOnchange.bind(this)}/>
                        <Filter label="Giới tính" type="option" name="gender" optionSelect={gender} action={this.inputOnchange.bind(this)}/>
                        <Filter label="CMT/CCCD" type="text" name="idCardNumber" action={this.inputOnchange.bind(this)}/>
                    </Row>
                </div>
                <div className=' mx-4 p-3 mt-2 w-120  text-center'>
                    <Row className='scroll-table  overflow-scroll-table'>
                        <Table className="table-bordered table-header-center" id="thong-tin-giao-dich">
                            <thead>
                                <tr className="sticky-header">
                                    {
                                        this.tableheader1.map((header,key)=>{
                                            return (
                                                <th className={header.label=='STT'?'sticky-col':''}  key={key} rowspan={header.row || "1"} colspan={header.col||"1"}>{header.label}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            <tr  className="cursor-pointer">
                            <td className="sticky-col">Tổng</td>
                            <td>{"-"}</td>
                            <td>{"-"}</td>
                      
                            <td>{ "-"}</td>
                            <td>{ "-"}</td>
                            <td>{ "-"}</td>
                            <td>{ "-"}</td>
                            <td>{ "-"}</td>
                            <td>{ utils.getMoneyFormat(this.state.total?this.state.total.total:0 ) }</td>
                            <td>{ "0"}</td>
                            <td>{"0"}</td>
                            <td>{"-"}</td>
                            <td>{ "-"}</td>
                            <td>{"-"}</td>
                            <td>{ "-"}</td>
                            </tr>
                                {
                                    utils.isEmpty(this.state.statisticData) ? null :
                                    this.state.statisticData.rows.map((row,key,)=>{
                                        return(
                                            <tr 
                                                className="cursor-pointer"
                                                key={key} 
                                                onClick={this.onClickRow.bind(this,row["history.affectedObjectId"],row["wallet.customer.id"],row["fluctuationTypeId"])}
                                            >
                                                <td className="sticky-col">{(this.filter.page-1)*this.filter.size+key+1}</td>
                                                <td>{row["id"] || "-"}</td>
                                                <td>{row["history.affectedObjectId"] || "-"}</td>
                                                <td>{row["ticketId"] || "-"}</td> {/* id vé chua sua */}
                                                <td>{row["wallet.customer.phoneNumber"] || "-"}</td>
                                                <td>{row["wallet.customer.fullName"] || "-"}</td>
                                                <td>{row["history.staff.username"] || "-"}</td>
                                                
                                                <td>{row["fluctuationTypeId"] || "-"}</td>
                                                <td>{utils.getMoneyFormat(row["value"]) || "0"}</td>
                                                <td>{utils.getMoneyFormat(row["beforeTransaction"]) || "0"}</td>
                                                <td>{utils.getMoneyFormat(row["afterTransaction"]) || "0"}</td>
                                                <td>{row["createdDate"] || "-"}</td>
                                                <td>{row["wallet.customer.email"] || "-"}</td>
                                                <td>{row["wallet.customer.gender"] || "-"}</td>
                                                <td>{row["wallet.customer.idCardNumber"] || "-"}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Row>
                    <Row className="mt-2">
                        <select class="custom-select float-left" name="size" style={{width:"90px"}} defaultValue={100} onChange={this.inputOnchange.bind(this)}>
                            {
                                this.rowPage.map((option,key)=>{
                                    return( <option key={key} value={option} >{option}</option>)
                                })
                            }
                        </select>
                        <div className="float-right px-2">
                            {
                                utils.isEmpty(this.state.statisticData) ? null
                                :(
                                    <div className="float-right px-2">
                                        <Button disabled={this.filter.page===1} onClick={this.getTransactions.bind(this,"-")}>{`<`}</Button>
                                        {
                                            utils.range(1,10).map((index)=>{
                                                return(
                                                    <Button 
                                                        className={`${this.filter.page === index ? "btn-danger" : null}`}
                                                        hidden={index >  this.state.pageNum}
                                                        onClick={this.getTransactions.bind(this,index)}
                                                    >
                                                        {index}
                                                    </Button>
                                                )
                                            })
                                        }
                                        <Button hidden={this.filter.page <= 10|| this.filter.page===this.state.pageNum} className="btn-danger">{`${this.filter.page}`}</Button>
                                        <Button hidden={this.state.pageNum<=10} className={`${this.filter.page === this.state.pageNum ? "btn-danger" : null}`} onClick={this.getTransactions.bind(this,this.state.pageNum)}>{`...${this.state.pageNum}`}</Button>
                                        <Button disabled={this.filter.page >=  this.state.pageNum} onClick={this.getTransactions.bind(this,"+")}>{`>`}</Button>
                                    </div>
                                )
                            }
                        </div>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default Main;
