import React from "react";
import { Collapse, Badge } from "reactstrap";
import "react-tabs/style/react-tabs.css";
import {  ChevronDown, } from "react-feather";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks, faChartLine,faDonate} from "@fortawesome/free-solid-svg-icons";

class SidebarCategory extends React.Component {
    render() {
        const { name, icon: Icon, isOpen, onClick } = this.props;
        return (
            <li className={"sidebar-item "}>
                <span data-toggle='collapse' className={"sidebar-link " + (!isOpen ? "collapsed" : "")} onClick={onClick} aria-expanded={isOpen ? "true" : "false"}>
                    {Icon ? <FontAwesomeIcon icon={Icon} className='align-middle mr-3' size='lg' /> : null}
                    <span className='align-middle'>{name}</span>

                    <ChevronDown size={18} className='align-middle float-right' />
                </span>
                <Collapse isOpen={isOpen}>
                    {name === "Thống kê" ? (
                        <React.Fragment>
                             <ul id='item'>
                                <SidebarItem name='Thống kê khách hàng v2' icon={null} setOptionTab={this.props.setOptionTab} id={this.props.id} click='userStatisticalV2' />
                            </ul>
                            <ul id='item'>
                                <SidebarItem name='Thống kê khách hàng' icon={null} setOptionTab={this.props.setOptionTab} id={this.props.id} click='userStatistical' />
                            </ul>
                            <ul id='item'>
                                <SidebarItem name='Thống kê giao dịch khách hàng' icon={null} setOptionTab={this.props.setOptionTab} id={this.props.id} click='customerTransactions' />
                            </ul>
                            <ul id='item'>
                                <SidebarItem name='Thống kê theo nhân viên' icon={null} setOptionTab={this.props.setOptionTab} id={this.props.id} click='staffStatistical' />
                            </ul>
                            <ul id='item'>
                                <SidebarItem name='Thống kê doanh thu' icon={null} setOptionTab={this.props.setOptionTab} id={this.props.id} click='revenueStatistics' />
                            </ul>
                            <ul id='item'>
                                <SidebarItem name='Thống kê tiền gửi theo kỳ' icon={null} setOptionTab={this.props.setOptionTab} id={this.props.id} click='moneyHoldingStatistics' />
                            </ul>
                            <ul id='item'>
                                <SidebarItem name='Thống kê tiền gửi theo ngày' icon={null} setOptionTab={this.props.setOptionTab} id={this.props.id} click='moneyHoldingStatisticsOnDay' />
                            </ul>
                            <ul id='item'>
                                <SidebarItem name='Thống kê hủy vé' icon={null} setOptionTab={this.props.setOptionTab} id={this.props.id} click='rejectTicket' />
                            </ul>
                            <ul id='item'>
                                <SidebarItem name='Thống kê trúng thưởng' icon={null} setOptionTab={this.props.setOptionTab} id={this.props.id} click='winPrize' />
                            </ul> 
                        </React.Fragment>
                    ) : name === "Lịch sử" ? (
                        <React.Fragment>
                            <ul id='item'>
                                <SidebarItem name='Lịch sử nhân viên' icon={null} setOptionTab={this.props.setOptionTab} id={this.props.id} click='clerkHistory' />
                            </ul>
                            <ul id='item'>
                                <SidebarItem name='Lịch sử kế toán' icon={null} setOptionTab={this.props.setOptionTab} id={this.props.id} click='accountantHistory' />
                            </ul>
                            <ul id='item'>
                                <SidebarItem name='Lịch sử nạp, rút tiền' icon={null} setOptionTab={this.props.setOptionTab} id={this.props.id} click='reChargeHistory' />
                            </ul>
                            <ul id='item'>
                                <SidebarItem name='Lịch sử admin' icon={null} setOptionTab={this.props.setOptionTab} id={this.props.id} click='adminHistory' />
                            </ul>
                            <ul id='item'>
                                <SidebarItem name='Lịch sử cá nhân' icon={null} setOptionTab={this.props.setOptionTab} id={this.props.id} click='historyPersonal' />
                            </ul>
                        </React.Fragment>
                    ) : null
                    }
                </Collapse>
            </li>
        );
    }
}

class SidebarItem extends React.Component {


    render() {
        const { name, badgeColor, badgeText, icon: Icon, id, click } = this.props;
        return (
            <li onClick={() => {this.props.setOptionTab(click)}} className={id === click ? "sidebar-item active" : "sidebar-item"}>
                <NavLink to="#" className='sidebar-link' activeClassName='active'>
                    {Icon ? <FontAwesomeIcon icon={Icon} className='align-middle mr-3' size='lg' /> : null}
                    {name}
                    {badgeColor && badgeText ? (
                        <Badge color={badgeColor} size={18} className='sidebar-badge'>
                            {badgeText}
                        </Badge>
                    ) : null}
                </NavLink>
            </li>
        );
    }
}

class SidebarLeft extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            select: null,
            openStatistical: false,
            openHistory: false,
            id: null,
        };
    }

    toggle(component) {
        this.setState({
            [component]: !this.state[component],
        }); 
    }

    render() {
        return (
            <div className='sidebar-content' style={{height:`${window.innerHeight-120}px`}}>
                <PerfectScrollbar>
                    <ul className='sidebar-nav'>
                        <React.Fragment>
                            <SidebarCategory
                                name='Thống kê'
                                icon={faChartLine}
                                setOptionTab={this.props.setOptionTab}
                                id={this.props.click}
                                isOpen={this.state[0]}
                                onClick={this.toggle.bind(this,0)}/>
                            <SidebarCategory
                                name='Lịch sử'
                                icon={faTasks}
                                setOptionTab={this.props.setOptionTab}
                                id={this.props.click}
                                isOpen={this.state[1]}
                                onClick={this.toggle.bind(this,1)}/>
                            <SidebarItem 
                                name='Nạp tăng hạn mức' 
                                icon={faDonate} 
                                setOptionTab={this.props.setOptionTab} 
                                id={this.props.click} 
                                click='budget' 
                            />
                        </React.Fragment>
                    </ul>
                </PerfectScrollbar>
            </div>
        );
    }
}

export default SidebarLeft;
