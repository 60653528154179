import React from "react";
import { Col, Row,  Modal, ModalHeader, ModalBody, ModalFooter, Button,Table, InputGroup, InputGroupAddon, Input, FormGroup, Label,Card, CardTitle, CardBody} from "reactstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {customerStatisticV2} from '../api/apiStatistic'
import {getAllGroup, updateGroup,getProduct,createGroup,getDetailGroup} from '../api/apiGroupBuy'
import apiAdmin, {adjustCustomer} from '../api/apiAdmin'
import Notification from './../../../components/Notification';
import utils from '../../../utils/index';
import {gender} from '../../../config/config';
import {arrayCity} from '../../../config/city'
import {getNameCityFromCode} from '../../../config/listCity';
import {getNameDistrictFromCode} from '../../../config/listDistrict.js'
import { Edit, Lock, Unlock, UserPlus } from "react-feather";
import {convertDate,isEmpty,range,getMoneyFormat} from "../../../utils";
const moment = require('moment')

let STATUS=['Nháp','Đang mở','Hoàn thành','Đã hủy','Đã in'];
const productDataset = [
    {
        "value": 1,
        "label": "Mega 6/45",
        "slug": "mega",
        "priceArray": [],
        "numberTicket": "6",
        "productOptionArray": [
            {
                "id": 1,
                "name": "Vé đơn",
                "slug": "ve_don",
                "select": 6,
                "startNumber": 1,
                "endNumber": 45,
                "price": 10
            },
            {
                "id": 2,
                "name": "Bao 5",
                "slug": "bao_5",
                "select": 5,
                "startNumber": 1,
                "endNumber": 45,
                "price": 400
            },
            {
                "id": 3,
                "name": "Bao 7",
                "slug": "bao_7",
                "select": 7,
                "startNumber": 1,
                "endNumber": 45,
                "price": 70
            },
            {
                "id": 4,
                "name": "Bao 8",
                "slug": "bao_8",
                "select": 8,
                "startNumber": 1,
                "endNumber": 45,
                "price": 280
            },
            {
                "id": 5,
                "name": "Bao 9",
                "slug": "bao_9",
                "select": 9,
                "startNumber": 1,
                "endNumber": 45,
                "price": 840
            },
            {
                "id": 6,
                "name": "Bao 10",
                "slug": "bao_10",
                "select": 10,
                "startNumber": 1,
                "endNumber": 45,
                "price": 2100
            },
            {
                "id": 7,
                "name": "Bao 11",
                "slug": "bao_11",
                "select": 11,
                "startNumber": 1,
                "endNumber": 45,
                "price": 4620
            },
            {
                "id": 8,
                "name": "Bao 12",
                "slug": "bao_12",
                "select": 12,
                "startNumber": 1,
                "endNumber": 45,
                "price": 9240
            },
            {
                "id": 9,
                "name": "Bao 13",
                "slug": "bao_13",
                "select": 13,
                "startNumber": 1,
                "endNumber": 45,
                "price": 17160
            },
            {
                "id": 10,
                "name": "Bao 14",
                "slug": "bao_14",
                "select": 14,
                "startNumber": 1,
                "endNumber": 45,
                "price": 30030
            },
            {
                "id": 11,
                "name": "Bao 15",
                "slug": "bao_15",
                "select": 15,
                "startNumber": 1,
                "endNumber": 45,
                "price": 50050
            },
            {
                "id": 12,
                "name": "Bao 18",
                "slug": "bao_18",
                "select": 18,
                "startNumber": 1,
                "endNumber": 45,
                "price": 185564
            }
        ]
    },
    {
        "value": 2,
        "label": "Power 6/55",
        "slug": "power",
        "priceArray": [],
        "numberTicket": "6",
        "productOptionArray": [
            {
                "id": 21,
                "name": "Vé đơn",
                "slug": "ve_don",
                "select": 6,
                "startNumber": 1,
                "endNumber": 55,
                "price": 10
            },
            {
                "id": 22,
                "name": "Bao 5",
                "slug": "bao_5",
                "select": 5,
                "startNumber": 1,
                "endNumber": 55,
                "price": 500
            },
            {
                "id": 23,
                "name": "Bao 7",
                "slug": "bao_7",
                "select": 7,
                "startNumber": 1,
                "endNumber": 55,
                "price": 70
            },
            {
                "id": 24,
                "name": "Bao 8",
                "slug": "bao_8",
                "select": 8,
                "startNumber": 1,
                "endNumber": 55,
                "price": 280
            },
            {
                "id": 25,
                "name": "Bao 9",
                "slug": "bao_9",
                "select": 9,
                "startNumber": 1,
                "endNumber": 55,
                "price": 840
            },
            {
                "id": 26,
                "name": "Bao 10",
                "slug": "bao_10",
                "select": 10,
                "startNumber": 1,
                "endNumber": 55,
                "price": 2100
            },
            {
                "id": 27,
                "name": "Bao 11",
                "slug": "bao_11",
                "select": 11,
                "startNumber": 1,
                "endNumber": 55,
                "price": 4620
            },
            {
                "id": 28,
                "name": "Bao 12",
                "slug": "bao_12",
                "select": 12,
                "startNumber": 1,
                "endNumber": 55,
                "price": 9240
            },
            {
                "id": 29,
                "name": "Bao 13",
                "slug": "bao_13",
                "select": 13,
                "startNumber": 1,
                "endNumber": 55,
                "price": 17160
            },
            {
                "id": 30,
                "name": "Bao 14",
                "slug": "bao_14",
                "select": 14,
                "startNumber": 1,
                "endNumber": 55,
                "price": 30030
            },
            {
                "id": 31,
                "name": "Bao 15",
                "slug": "bao_15",
                "select": 15,
                "startNumber": 1,
                "endNumber": 55,
                "price": 50050
            },
            {
                "id": 32,
                "name": "Bao 18",
                "slug": "bao_18",
                "select": 18,
                "startNumber": 1,
                "endNumber": 55,
                "price": 185564
            }
        ]
    }
];
const Product=['','Mega 6/45','Power 6/55']
class ModalTicket extends React.Component {
    
    constructor(props){
        super(props);
        this.state={
            holders:[]
        }
    }

    componentDidMount(){
        getDetailGroup({groupBuyId:this.props.group.id},(err,res)=>{
            if(err){
                alert(err);
            }else{
                this.setState({holders:res.stockBuy});                
            }
          
        })
    }
    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.showModalTicket} className='w800'>
                    <ModalHeader toggle={this.props.closeModalTicket} >
                        <div className="font-weight-bold">
                        {`Vé: ${Product[this.props.group.productId]}, Số tiền: ${utils.getMoneyFormat(this.props.group.ticketPrice)} `}
                        </div>
                    </ModalHeader>
                    <ModalBody  >
                        {                                                
                        <Card >
                            <CardTitle>{`Kiểu chơi: ${this.props.group.productOption.name}, Tiền vé:${utils.getMoneyFormat(this.props.group.ticketPrice)}, Kỳ: ${this.props.group.term.termString+' - '+this.props.group.term.date}, Id:${this.props.group.id} `}</CardTitle>
                            <CardBody className="pt-0">
                                                                                              
                                <div className="d-flex">
                                    <div className="d-flex align-items-center w-10">
                                        <span class="text-danger">A</span>
                                    </div>
                                    <div className="d-flex flex-wrap w-90">
                                        {
                                            this.props.group.numberCurrent?.split(',').map((number,k)=>{                                                
                                                return(<span class="mx-1" key={k}>{number}</span>)
                                            })
                                        }
                                    </div>
                                </div>
                                        
                          
                                <Row>
                                    <Col md="6" className="text-center">
                                        <img alt="mat truoc" className="w-90" src={utils.getURLImage(this.props.group.imageFront)}/>
                                    </Col>
                                   
                                </Row>
                                <Row>
                                    <Table className="table-bordered table-header-center" id="thong-ke-khach-hang">
                                        <tr>
                                            <th>#</th>
                                            <th>Số điện thoại</th>
                                            <th>Bộ số dề cử</th>
                                            <th>Cổ phẩn</th>
                                            <th>Số tiền</th>
                                            
                                        </tr>
                                        {
                                            this.state.holders.map(d=>{
                                                return (
                                                    <tr>
                                                        <td>{d.id}</td>
                                                        <td>{d.phoneNumber}</td>
                                                        <td>{d.numberSelect}</td>
                                                        <td>{d.stockBuy}</td>
                                                        <td>{utils.getMoneyFormat( d.price)}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </Table>
                                </Row>
                            </CardBody>
                        </Card>
                                    
                             
                        }
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}
class GroupBuyManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),           
            phone:null,
            pageNum:1,
            data:[],
            size:50,            
            showModalTicket:false, 
            statusId:null,
            limit:10,
            offset:0,
            productId:null,
            productOptionId:null,
            term:[],
            termSelect:null,
            isLoading:false,
            modalCreate:false,
            showModalTicket:false,
            timeClose:'',
            termFilter:null,
            isWining:false
        };
        this.rowPage = [50,100,200,250];
        this.tableheader1=[
            {label: "Id "},
            {label: "Ngày tạo"},   
            {label: "Tạo bởi"},         
            {label: "Loại vé"},
            {label: "Kỳ mở thưởng"},
            {label: "% Hoàn thành"},
            {label: "Thời gian đóng"},
            {label: "Trạng thái"},
            {label: "Hành động"},                       
        ];
        this.filter={
            size: 50,
            page:1,
            option_totalMoney: "",
            option_totalAddMoney: "",
            option_totalWinning: "",
            option_totalWithdrawal: "",
            option_totalPayForTicket: "",
        };
        this.smallOption=["totalMoney","totalAddMoney","totalWinning","totalWithdrawal","totalPayForTicket"];
    }

    
    getData(){    
        if(this.state.isLoading)   
            return;
        else this.setState({isLoading:true})
        let obj={limit:this.state.limit,offset:this.state.offset};
        if(this.state.productIdSearch)
            obj.productId=this.state.productIdSearch;
        if(this.state.statusIdSearch!=null)
            obj.statusId=this.state.statusIdSearch;
        if(this.state.termFilter!=null && this.state.termFilter!='')
        obj.term=this.state.termFilter;
        if(this.state.isWining){
            obj.limit=10000000;
        obj.isWining=this.state.isWining;
        }
        getAllGroup(obj,(err,data)=>{
            if(err){
                alert(err);
            }else{
                console.log(data);
                let totalWining=0;
                if(obj.isWining){
                    data.map(e=>{
                        totalWining+=e.winingNumber[0].profit
                    })
                }
                this.setState({data:data,totalWining:totalWining});                
            }
            this.setState({isLoading:false})
        })
    }   
    
    inputOnchange(event) {      
        console.log(event.target.value)  
                this.setState({
                    [event.target.name]: event.target.value,                    
            });                        
    }
    
    componentDidMount(){
        this.getData(null);
    }
    toggle(component1, component2, value) {
        let state = utils.getRawData(this.state);
        if (component2) {
            if (value) {
                state[component1][component2] = value;
                this.setState(state);
            } else {
                state[component1][component2] = !state[component1][component2];
                this.setState(state);
            }
        } else {
            if (value) {
                state[component1] = value;
                this.setState(state);
            } else {
                state[component1] = !state[component1];
                this.setState(state);
            }
        }
    }
    handCreateGroup(){
        let data={
            productId:this.state.productId,
            productOptionId:this.state.productOptionId,
            term:this.state.termSelect,
            timeClose:this.state.timeClose
        }
        createGroup(data,(err,res)=>{
            if(err) alert(err);
            else {this.setState({modalCreate:false}); this.getData()}
        })
    }
    handleSelectProduct(){
        getProduct(this.state.productId,(err,data)=>{
            if(err){}
            else{
                this.setState({term:data.term})
            }
        })
    }
    handleChangeStatus(status,id){
        updateGroup({data:{statusId:status},id},(err,res)=>{
            if(err) alert(err);
            else{
                this.getData();
            }
        })
    }
    handleNext(){
        if(this.state.data.length===0)
        {
            return;
        }
            
        this.setState({offset:this.state.offset+this.state.limit},()=>{
            this.getData()
        });
    }
    handleBack(){
        if(this.state.offset===0)
        {
            return;
        }
            
        this.setState({offset:this.state.offset-this.state.limit},()=>{
            this.getData()
        });
    }
    render() {
        return(
            <React.Fragment>
            {this.state.showModalTicket&&<ModalTicket
                group={this.state.data.find(e=>e.id==this.state.selectGroup)}
                showModalTicket={this.state.showModalTicket}
                closeModalTicket={()=>this.toggle('showModalTicket','')}
            />}
                <Modal isOpen={this.state.modalCreate}>
                        <ModalHeader>Tạo mới nhóm</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label>Loại vé</Label>
                                <Select
                                    name='productId'
                                    className='react-select-container'
                                    classNamePrefix='react-select'
                                    options={productDataset}
                                    onChange={(value)=>{
                                       this.setState({productId:value.value},()=>{
                                        this.handleSelectProduct();
                                       });
                                       
                                    }}
                                />
                            </FormGroup>
                            {this.state.productId&&<FormGroup>
                                <Label>Kiểu chơi</Label>
                                <Select
                                    name='productOptionId'
                                    className='react-select-container'
                                    classNamePrefix='react-select'
                                    options={productDataset.find(v=>v.value==this.state.productId).productOptionArray.map(e=>{
                                        return {
                                            value:e.id,
                                            label:e.name
                                        }
                                    })}
                                    onChange={(value)=>this.setState({productOptionId:value.value})}
                                />
                            </FormGroup>}
                            {this.state.term.length>0&&<FormGroup>
                                <Label>Kỳ mở thưởng</Label>
                                <Select
                                    name='term'
                                    className='react-select-container'
                                    classNamePrefix='react-select'
                                    options={this.state.term.map(e=>{
                                        return {
                                            value:e.termValue,
                                            label:e.termString+' '+e.date
                                        }
                                    })}
                                    onChange={(value)=>{
                                       
                                        this.setState({termSelect:value.value,timeClose:this.state.term.find(e=>e.termValue==value.value).date.replace("00:00:00","17:30:00")})
                                       
                                    
                                    }}
                                />
                            </FormGroup>}
                            <InputGroup className='mb-3'>
                                <InputGroupAddon addonType='prepend'>Thời gian đóng</InputGroupAddon>
                                <Input name='timeClose' disabled onChange={this.inputOnchange.bind(this)} value={this.state.timeClose} placeholder="DD-MM-YYYY HH:mm:ss"/>
                            </InputGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color='secondary' onClick={this.toggle.bind(this, "modalCreate", null, false)}>
                                Hủy bỏ
                            </Button>
                            <Button color='success' onClick={this.handCreateGroup.bind(this)}>
                                Lưu
                            </Button>
                        </ModalFooter>
                    </Modal>
                <div className='mx-4 p-3 w-120'>
                    
                    <Row className='justify-content-center m-0 mt-3'>
                        <FormGroup  className='mb-3 ml-3'>
                            <Label className="d-block">Loại vé</Label>
                            <Select
                                    name='productId'
                                    className='header-select-statistic'
                                    classNamePrefix='react-select'
                                    options={[{value:null,label:'Tất cả'}].concat(productDataset)}
                                    onChange={(value)=>{
                                       this.setState({productIdSearch:value.value});                                       
                                    }}
                                />
                        </FormGroup>   
                        <FormGroup  className='mb-3 ml-3'>
                        <Label className="d-block">Trạng thái</Label>
                            <Select
                                    name='productId'
                                    className='header-select-statistic'
                                    classNamePrefix='react-select'
                                    options={[{value:null,label:'Tất cả'},{value:0,label:'Nháp'},{value:1,label:'Đang mở'},{value:2,label:'Hoàn thành'},{value:3,label:'Đã hủy'},{value:4,label:'Đã in'}]}
                                    onChange={(value)=>{
                                       this.setState({statusIdSearch:value.value});                                       
                                    }}
                                />
                        </FormGroup>
                        <FormGroup  className='mb-3 ml-3'>
                        <Label className="d-block">Kỳ mở thưởng</Label>
                            <Input
                                    name='termFilter'
                                    placeholder='000'
                                    className='header-select-statistic'
                                    classNamePrefix='react-select'                                   
                                    onChange={this.inputOnchange.bind(this)}
                                />
                        </FormGroup>
                        <div className='mt-4 p-2 d-inline'>
                            <Button color={this.state.isWining?'danger':'secondary'} onClick={()=>{
                                this.setState({isWining:!this.state.isWining});
                            }} >
                                Lọc vé trúng thưởng {this.state.isWining?'✅':'❎'}
                            </Button>
                        </div>
                        <div className='mt-4 p-2 d-inline'>
                            <Button color='primary' onClick={()=>this.getData()} >
                                Tìm kiếm
                            </Button>                            
                        </div>
                    </Row>
                   
                </div>
               
                <div className=' mx-4 p-3 mt-2 w-120 text-center'>
                <Row style={{flexDirection:'row',justifyContent:'flex-end'}}>
                        <Button onClick={this.toggle.bind(this, "modalCreate", null, false)}>Tạo mới</Button>
                </Row>
                {this.state.isWining&&<Row style={{flexDirection:'row'}}>
                       <p>Tổng tiền trúng thưởng:{getMoneyFormat(this.state.totalWining)}</p>
                </Row>}
                    <Row className='pt-2 p-1 scroll-table  '>
                        <Table className="table-bordered table-header-center" id="thong-ke-khach-hang">
                            <thead>
                                
                                <tr>
                                    {
                                        this.tableheader1.map((header,key)=>{
                                            return (
                                                <th rowspan={header.row || "1"} colspan={header.col||"1"}>{header.label}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    utils.isEmpty(this.state.data) ? null :
                                    this.state.data.map((row)=>{
                                        return(
                                            <tr >
                                               <td>{row.id}</td>
                                               <td>{row.createdOn}</td>
                                               <td>{row.createdBy}</td>
                                               <td>{Product[row.productId]+'-'+row.productOption.name}</td>
                                               <td>{row.term.termString+' '+row.term.date}</td>
                                               <td>{row.percentCurrent  } %</td>
                                               <td>{row.timeClose  } </td>
                                               <td>
                                                
                                                    <span >{STATUS[ row.statusId]}</span>
                                                
                                               </td>
                                               <td >
                                               <Button color='primary' onClick={()=>{this.setState({selectGroup:row.id,showModalTicket:true})}} >
                                                    Chi tiết
                                                </Button>  
                                                {row.statusId===0&&
                                                <Button color='success' onClick={()=>{this.handleChangeStatus(1,row.id)}}>
                                                    Mở bán
                                                </Button> 
                                                }                                                
                                                {row.statusId===1&&
                                                    <Button color='danger'>
                                                        Dời kỳ sau
                                                    </Button> 
                                                }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <Button color='primary' onClick={()=>this.handleBack()}>{'<'}</Button>
                                    <span>...</span>
                                    <Button color='primary'  onClick={()=>this.handleNext()}>{'>'}</Button>
                                </tr>
                            </tbody>
                        </Table>
                    </Row>
                   
                </div>
            </React.Fragment>
        );
    }
}

export default GroupBuyManager;