import React from "react";

import {
    Modal,ModalHeader,ModalBody,ModalFooter,
    Button,
    InputGroup,InputGroupAddon,Input,
    FormFeedback,
    UncontrolledDropdown,
    DropdownToggle,DropdownMenu,DropdownItem,
    Row, Col
} from "reactstrap";
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { User} from "react-feather";
import "react-tabs/style/react-tabs.css";
import "./css/clerk.css";
import Notification from "../../components/Notification";
import utils from "../../utils";
import Order from "./contentBuyTicketClerk"
import Redeem from "./contentRedeemClerk"
import ReCharge from "./contentRechargeClerk"
import History from "./contentHistoryClerk"
import PushLotteryResults from "./pushLotteryResults"
import Select from 'react-select';
import ModalCamera from "../../components/ModalCamera";

const productId = require('./../../assets/json/product.json');
const changePassword = require("../apiGeneral/changePassword");
const {getCurrentTerm} = require('./api/apiTicket')
const {rejectTicket} = require('./api/apiPos')


class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            currentTermKeno : {}
        }
    }

    getCurrentTermKeno(){
        getCurrentTerm(6,(err,result)=>  {
            if(err){
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                this.setState({currentTermKeno: result});
            }
        });
    }

    handLogout(){
        if(utils.isEmpty(this.props.dataTickets)){
            utils.logOut();
        }else{
            Notification("error","Không thể đăng xuất khi chưa xử lý hết vé","")
        }
    }

    componentDidMount(){
        this.getCurrentTermKeno();
        setInterval(() => {
            this.getCurrentTermKeno();
        }, 60*1000);
    }

    shouldComponentUpdate(nextProps,nextState){
        return nextState.currentTermKeno.termValue !== this.state.currentTermKeno.termValue;
    }

    render() {
        return (
            <div className="w-100 d-inline">
                <div className="d-inline pt-1 pb-0 text-primary h4 ">
                    <UncontrolledDropdown className='text-danger float-right mr-1'>
                        <span>
                            <DropdownToggle nav caret>
                                <User />
                                <button className='text-dark bg-transparent border-0'>{utils.getUserinfo().full_name} </button>
                            </DropdownToggle>
                        </span>
                        <DropdownMenu right>
                            <DropdownItem
                                onClick={this.props.toggleModalChangepass}
                            >
                                Đổi mật khẩu
                            </DropdownItem>
                            <DropdownItem
                                onClick={this.props.toggleModalRejectTicket}
                            >
                                Hủy vé in lỗi
                            </DropdownItem>
                            <DropdownItem
                                onClick={this.handLogout.bind(this)}>
                                Đăng xuất
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <div className="h4 float-right d-inline mr-4 mt-2 cursor-pointer" onClick={this.getCurrentTermKeno.bind(this)}>
                        <p className="d-inline"> {`Kỳ keno hiện tại: `} </p>
                        <p className="text-danger d-inline"> {`#${this.state.currentTermKeno.termValue} `} </p>
                        <p className="d-inline"> {` ${this.state.currentTermKeno.date}`} </p>
                    </div>
                </div>
            </div>
        );
    }
}

class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openModalChangePassword: false,
            oldPassword : null,
            newPassword: null,
            rePassword: null,
            tabSelected: "order",
            // tabSelected: "pushLotteryResult",

            money: 0,
        }
        this.productId=[];
    }

    inputOnchange(event){
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    toggle(p){
        this.setState({
            [p]: !this.state[p]
        });
    }
    handleChangePassword(){
        if(utils.isEmpty(this.state.oldPassword) || utils.isEmpty(this.state.rePassword) || this.state.newPassword!== this.state.rePassword){
            Notification("error","Thiếu thông tin");
        }else{
            changePassword.changePassword(
                {currentPassword: this.state.oldPassword,password:this.state.newPassword},
                (err,result)=>{
                    if(err){
                        Notification("error","Sai thông tin mật khẩu");
                    } else {
                        Notification("success","Đổi mật khẩu thành công");
                        window.location.href = "/";
                    }
                }
            )
        }
    }

    handleRejectTicket(){
        if(utils.isEmpty(this.state.productId)) return Notification("error","Loại vé không được để trống");
        if(utils.isEmpty(this.state.money)) return Notification("error","Giá trị vé không được để trống");
        if(utils.isEmpty(this.state.money%1000!==0)) return Notification("error","Giá trị vé phải chia hết cho 1000");
        // if(utils.isEmpty(this.state.reason)) return Notification("error","Lý do không được để trống");
        if(utils.isEmpty(this.state.reprint)) return Notification("error","Chọn in lại vé đúng hay không");
        if(utils.isEmpty(this.state.imgLink)) return Notification("error","Chụp ảnh vé lỗi");
        rejectTicket(parseInt(this.state.productId),this.state.money/1000,this.state.reason,this.state.reprint,utils.dataURLtoFile(this.state.imgSrc),(err,result)=>{
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
                Notification("success", "Thêm thông tin thành công");
                this.setState({
                    reprint : null,
                    productId : null,
                    money : 0,
                    imgLink : null,
                    imgSrc : null,
                    reason : null,
                    openModalRejectTicket: false
                })
            }
        })
    }

    listProductId(){
        for (const key in productId) {
            if (productId.hasOwnProperty(key)) {
                this.productId.push({label: productId[key], value: key});
            }
        }
    }

    moneyChange(event) {
        let amount = event.target.value.replace(/[^\d]/g, "");
        if (utils.isEmpty(amount))
            amount = 0;
        try {
            amount = parseInt(amount);
            if (Number.isNaN(amount))
                return;
            this.setState({[event.target.name]: amount});
            return;
        } catch (e) {
            return;
        }
    }

    handleShootingTicket(id,imgSrc, imgLink) {
        this.setState({
            imgSrc: imgSrc, 
            imgLink:imgLink,
            disabledShooting: true,
        });
    }

    handleRemovePhoto(id) {
        this.setState({
            imgSrc: null, 
            imgLink:null,
            disabledShooting: false,
        });
    }

    componentDidMount(){
        this.listProductId();
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.tab === "ruttien" && this.state.tab!== "ruttien"){
            this.setState({"tab": "ruttien"})
        }
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.state.openModalChangePassword}>
                    <ModalHeader>Đổi mật khẩu</ModalHeader>
                    <ModalBody>
                        <InputGroup className='mb-3'>
                            <InputGroupAddon addonType='prepend'>Mật khẩu cũ</InputGroupAddon>
                            <Input type='password' name='oldPassword' invalid={utils.isEmpty(this.state.oldPassword) ? true : false} onChange={this.inputOnchange.bind(this)} />
                            <FormFeedback invalid>Mật khẩu cũ không được để trống</FormFeedback>
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroupAddon addonType='prepend'>Mật khẩu mới</InputGroupAddon>
                            <Input type='password' name='newPassword' invalid={utils.isEmpty(this.state.newPassword) ? true : false} onChange={this.inputOnchange.bind(this)} />
                            <FormFeedback invalid>Mật khẩu mới không được để trống</FormFeedback>
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <InputGroupAddon addonType='prepend'>Nhập lại mật khẩu</InputGroupAddon>
                            <Input
                                type='password'
                                name='rePassword'
                                invalid={this.state.newPassword !== this.state.rePassword ? true : false}
                                readOnly={this.state.newPassword ? false : true}
                                onChange={this.inputOnchange.bind(this)}
                            />
                            <FormFeedback invalid>Mật khẩu không khớp</FormFeedback>
                        </InputGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='secondary' onClick={this.toggle.bind(this,"openModalChangePassword")}>
                            Hủy bỏ
                        </Button>
                        <Button color='success' onClick={this.handleChangePassword.bind(this)}>
                            Lưu
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.openModalRejectTicket} size="lg">
                    <ModalHeader>Hủy vé in lỗi</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="6">
                                <Select
                                    name='productId'
                                    className='w-100 mt-3'
                                    placeholder="Chọn loại vé"
                                    classNamePrefix='react-select'
                                    options={this.productId || []}
                                    onChange={(value)=>this.setState({productId: value.value})}
                                />
                                <InputGroup size='lg' className='mt-3'>
                                    <InputGroupAddon addonType={"append"}>Giá trị</InputGroupAddon>
                                    <Input
                                        type='text'
                                        name='money'
                                        onChange={this.moneyChange.bind(this)}
                                        value={this.state.money.getMoneyFormat()}
                                        maxLength='15'
                                    />
                                </InputGroup>
                                <InputGroup size='lg' className='mt-3'>
                                    <InputGroupAddon addonType={"append"}>Lý do</InputGroupAddon>
                                    <Input
                                        type='text'
                                        name='reason'
                                        onChange={this.inputOnchange.bind(this)}
                                        maxLength='200'
                                    />
                                </InputGroup>
                                <Select
                                    name='reprint'
                                    className='w-100 mt-3'
                                    placeholder="Trạng thái hoàn trả vé"
                                    classNamePrefix='react-select'
                                    options= {[
                                        {value: true, label: "Hoàn trả vé cho vietlott"},
                                        {value: false, label: "Không hoàn trả vé cho vietlott"}
                                    ]}
                                    onChange={(value)=>this.setState({reprint: value.value})}
                                />
                            </Col>
                            <Col md="6" className="text-center">
                                <ModalCamera
                                    label="Ảnh vé bị hủy"
                                    id="rejectTicket"
                                    keyImg="front"
                                    imgSrc = {this.state.imgSrc}
                                    disabledShooting = {this.state.disabledShooting || false}
                                    actionWhenReceiveData = {this.handleShootingTicket.bind(this)}
                                    actionWhenDeleteImage = {this.handleRemovePhoto.bind(this)}
                                    noAction = {true}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='secondary' onClick={this.toggle.bind(this,"openModalRejectTicket")}>
                            Hủy bỏ
                        </Button>
                        <Button color='success' onClick={this.handleRejectTicket.bind(this)}>
                            Lưu
                        </Button>
                    </ModalFooter>
                </Modal>

                <Header
                    toggleModalChangepass = {this.toggle.bind(this,"openModalChangePassword")}
                    toggleModalRejectTicket = {this.toggle.bind(this,"openModalRejectTicket")}
                    toggleSidebar = {this.props.toggle.bind(this)}
                />
                {/* <Tabs className="w-100" activeKey={this.state.tabSelected} id="uncontrolled-tab-example" onSelect={(tab)=>this.props.setTab(tab)}> */}
                <Tabs className="w-100" activeKey={this.props.tabSelected} id="uncontrolled-tab-example" onSelect={(tab)=>this.props.setTab(tab)}>
                    <Tab className="pt-2 tab-order" eventKey="order" title="Bán vé">
                        <Order 
                            data={this.props.dataTickets} 
                            handTicketChangeStatus={this.props.handTicketChangeStatus} 
                            toggle={this.props.toggle} 
                        />
                    </Tab>
                    <Tab className="pt-2 tab-redeem" eventKey="redeem" title="Rút tiền">
                        <Redeem 
                            data = {this.props.dataRedeem}
                            clearDataRedeem = {this.props.clearDataRedeem}
                            handRedeemChangeStatus = {this.props.handRedeemChangeStatus}
                        />
                    </Tab>
                    <Tab className="pt-2 tab-recharge" eventKey="reCharge" title="Nạp tiền tại quầy">
                        <ReCharge/>
                    </Tab>
                    <Tab className="pt-2 tab-history" eventKey="history" title="Lịch sử giao dịch">
                        <History/>
                    </Tab>
                    <Tab className="pt-2 tab-push-lottery-result" eventKey="pushLotteryResult" title="Nhập kết quả">
                        <PushLotteryResults
                            tabSelected={this.props.tabSelected}
                        />
                    </Tab>
                </Tabs>
            </React.Fragment>
        );
    }
}

export default Content;