import React from "react";
import { Row, Label, FormGroup, Button,Table,Col, InputGroup,CustomInput,ModalHeader,Modal,ModalBody, Card, CardTitle, CardBody} from "reactstrap";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {customerStatisticV2} from '../api/apiStatistic'
import {getRevenueMonitor,getTicketArrayRevenue,getGroupArray} from '../api/apiAdmin'
import {adjustCustomer} from '../api/apiAdmin'
import Notification from './../../../components/Notification';
import utils from '../../../utils/index';
import {gender} from '../../../config/config';
import {arrayCity} from '../../../config/city'
import {getNameCityFromCode} from '../../../config/listCity';
import {getNameDistrictFromCode} from '../../../config/listDistrict.js'
import { Edit, Lock, Unlock, UserPlus } from "react-feather";
const {getProductById,getProductOptionById} = require('../../../assets/js/getProduct');
const moment = require('moment')

class ModalTicket extends React.Component {
    
    getTicketCount(){
        let sum=0
        this.props.ticketArray.map(e=>{
            sum+=1*e.termArray.length
        })
        return sum
    }
    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.showModalTicket}>
                    <ModalHeader toggle={this.props.closeModal}>
                        <div className="font-weight-bold">
                        {
                            
                            `Vé: ${getProductById(this.props.productId)}, Số tiền: ${utils.getMoneyFormat(this.props.revenue)}, Số vé:${this.getTicketCount()} `
                        }
                        </div>
                    </ModalHeader>
                    <ModalBody>
                    {
                            
                            this.props.groupTicketData.map((ticket,key)=>{
                                let price=ticket.ticketPrice;
                               
                                return(
                                    <Card key = {key}>
                                        <CardTitle>{`Kiểu chơi: Mua Chung ${getProductOptionById(ticket.productOptionId)}, Tiền vé:${utils.getMoneyFormat(price)}, Id:${ticket.id} `}</CardTitle>
                                        <CardBody className="pt-0">
                                            { // hien so
                                           
                                                        <div className="d-flex">
                                                            <div className="d-flex align-items-center w-10">
                                                                
                                                            </div>
                                                            <div className="d-flex flex-wrap w-90">
                                                            {ticket.numberCurrent}
                                                            
                                                               
                                                            </div>
                                                        </div>
                                                    
                                            }
                                            {/* Ảnh */}
                                            <Row>
                                                <Col md="6" className="text-center">
                                                    <img alt="mat truoc" className="w-90" src={utils.getURLImage(ticket.imageFront)}/>
                                                </Col>
                                               
                                            </Row>
                                           
                                        </CardBody>
                                    </Card>
                                    
                                )
                            })
                        }
                        {
                            
                            this.props.ticketArray.map((ticket,key)=>{
                                let price=0;
                                for(let val of ticket.value){
                                    price+=val.price;
                                }
                                price*=ticket.termArray.length;
                                return(
                                    <Card key = {key}>
                                        <CardTitle>{`Kiểu chơi: ${getProductOptionById(ticket.productOptionId)}, Tiền vé:${utils.getMoneyFormat(price)}, Số kỳ: ${ticket.termArray.length}, Id:${ticket.id} `}</CardTitle>
                                        <CardBody className="pt-0">
                                            { // hien so
                                                ticket.value.map((rowNumber,index)=>{
                                                    return(
                                                        <div className="d-flex">
                                                            <div className="d-flex align-items-center w-10">
                                                                <span class="text-danger">{String.fromCharCode(65+index)}</span>
                                                            </div>
                                                            <div className="d-flex flex-wrap w-90">
                                                            
                                                                {this.props.productId!=13?
                                                                    rowNumber.number.map((number,k)=>{
                                                                        if(ticket.productOptionId===82){
                                                                            let kenoEvenOddBigSmall={
                                                                                "1": "Lẻ",
                                                                                "2": "Chẵn",
                                                                                "3": "Nhỏ",
                                                                                "4": "Lớn"
                                                                            };
                                                                            return (<span class="mx-1" key={k}>{kenoEvenOddBigSmall[number]}</span>)
                                                                        }
                                                                        return(<span class="mx-1" key={k}>{number}</span>)
                                                                    }):(<span class="mx-1">{rowNumber.number}</span>)
                                                                }
                                                               { this.props.productId!=13?<span>-{rowNumber.price}K</span>: (<><span>-{rowNumber.price}K</span> <div>{rowNumber.ticketCodes.join('; ')}</div></>)
                                                               }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* Ảnh */}
                                            <Row>
                                                <Col md="6" className="text-center">
                                                    <img alt="mat truoc" className="w-90" src={utils.getURLImage(ticket.imageFront)}/>
                                                </Col>
                                                <Col md="6" className="text-center">
                                                    <img alt="mat truoc" className="w-90" src={utils.getURLImage(ticket.imageBack)}/>
                                                </Col>
                                            </Row>
                                           
                                        </CardBody>
                                    </Card>
                                    
                                )
                            })
                        }
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}
class StaffMonitor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),           
            phone:null,
            pageNum:1,
            statisticData : [],
            statisticDataShow:[],
            size:50,
            ticketArray:[],
            showModalTicket:false,
            productSelect:null,
            revenueSelect:null,
            groupTicketData:[]
        };
        this.rowPage = [50,100,200,250];
        this.tableheader1=[
            {label: "POS"},          
            {label: "Mega645"},
            {label: " Power655"}, 
            {label: " Max3D Pro"},
            {label: " Max3D"},
            {label: " Max3DPlus"},
            {label: " Keno"},
                
        ];
        this.filter={
            size: 50,
            page:1,
            option_totalMoney: "",
            option_totalAddMoney: "",
            option_totalWinning: "",
            option_totalWithdrawal: "",
            option_totalPayForTicket: "",
        };
        this.smallOption=["totalMoney","totalAddMoney","totalWinning","totalWithdrawal","totalPayForTicket"];
    }

    
    getStatistic(page){
       
      let query={
          date:moment(this.state.date).format('DD/MM/YYYY'),
          
      }      
      getRevenueMonitor(
        query.date,
            (err, result) => {
            if (err) {
                Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
            } else {
           
                this.setState({statisticData: result,statisticDataShow:result.slice(0,this.filter.size), pageNum : Math.ceil(result.length/this.filter.size)})
            }
        });
    }
    
   
    
    componentDidMount(){
        this.getStatistic(null);
    }
    selectRevenue(productId,walletId,revenue){

        if(revenue){            
            getTicketArrayRevenue(walletId,moment(this.state.date).format('DD/MM/YYYY'),productId,(err,data)=>{
            if(!err){
                this.setState({selectProduct:productId,selectRevenue:revenue,ticketArray:data.tickets,groupTicketData:data.baochung,showModalTicket:true})
            }
            else Notification("error", "Có lỗi xảy ra ", "Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên");console.log("Kiểm tra lại các trường đầu vào hoặc báo cho quản trị viên",err)
        });
        // getGroupArray(revenue.groupIds,(err,data)=>{
        //     if(!err)
        //     this.setState({groupTicketData:data})
        // })
    }
    }
    toggle(component){
        this.setState({
            [component]: !this.state[component]
        });
    }
    render() {
        return(
            <React.Fragment>
                {
                        this.state.ticketArray?(
                            <ModalTicket
                                groupTicketData={this.state.groupTicketData}
                                ticketArray = {this.state.ticketArray}
                                showModalTicket = {this.state.showModalTicket}
                                productId={this.state.selectProduct}
                                revenue={this.state.selectRevenue}
                                closeModal = {this.toggle.bind(this,"showModalTicket")}
                            />
                        ):null
                }
                <div className='mx-4 p-3 w-120 text-center'>
                    
                    <Row className='justify-content-center m-0 mt-3'>
                        <FormGroup className='mb-3'>
                            <Label className="d-block">Chọn ngày cần xem</Label>
                            <DatePicker
                                isClearable
                                showPopperArrow={false}
                                selected={this.state.date}
                                onChange={date =>this.setState({date: date})}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>                        
                        <div className='mt-4 p-2 d-inline'>
                            <Button color='primary' onClick={this.getStatistic.bind(this,null)}>
                                Tìm kiếm
                            </Button>                            
                        </div>
                    </Row>
                   
                </div>
                <div className=' mx-4 p-3 mt-2 w-120 text-center'>
                    <Row className='pt-2 p-1 scroll-table  '>
                        <Table className="table-bordered table-header-center" id="thong-ke-khach-hang">
                            <thead>
                                <tr>
                                    <th >#</th>
                                    <th colSpan='12'>Doanh thu từng vé</th>
                                </tr>
                                <tr>
                                    {
                                        this.tableheader1.map((header,key)=>{
                                            return (
                                                <th rowspan={header.row || "1"} colspan={header.col||"1"}>{header.label}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    utils.isEmpty(this.state.statisticData) ? null :
                                    this.state.statisticDataShow.map((row)=>{
                                        return(
                                            <tr >
                                                <td>{row.posCode}</td>
                                                <td onClick={this.selectRevenue.bind(this,1,row.walletId,row.mega)}>{utils.getMoneyFormat(row.mega) }</td>
                                                <td onClick={this.selectRevenue.bind(this,2,row.walletId,row.power)}>{utils.getMoneyFormat(row.power) }</td>

                                                <td onClick={this.selectRevenue.bind(this,12,row.walletId,row.max3dpro)}>{utils.getMoneyFormat(row.max3dpro) }</td>
                                                <td onClick={this.selectRevenue.bind(this,4,row.walletId,row.max3d)}>{utils.getMoneyFormat(row.max3d) }</td>
                                                <td onClick={this.selectRevenue.bind(this,5,row.walletId,row.max3dplus)}>{utils.getMoneyFormat(row.max3dplus) }</td>
                                                <td onClick={this.selectRevenue.bind(this,6,row.walletId,row.keno)}>{utils.getMoneyFormat(row.keno)}</td>
                
                                                                                       
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Row>
                   
                </div>
            </React.Fragment>
        );
    }
}

export default StaffMonitor;